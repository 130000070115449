.view-container {
  flex-grow: 1;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width:100%;
  box-sizing:border-box;
}

.view-container-vertical {
  flex-grow: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width:100%;
  box-sizing:border-box;
}

.subview-container {
  flex-grow: 1;
  display:flex;
  overflow-y: auto;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
}

.mezclado-tabla {
  flex-grow: 2;
  width: 40%;
  margin: 5px;
  border-radius: 5px;
}

.mezclado-mapa-container {
  flex-grow: 1;
  /* width:39%; */
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.mezclado-mapa {
  width: 100%;
  flex-grow: 1;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 1040px) {
  .mezclado-tabla,
  .stock-tabla {
    width: 98%;
  }
  .mezclado-mapa-container {
    width: 100%;
    height: 70vh;
  }
}

.grid-mapa-container {
  height: 60vh;
  position: relative;
}

.stock-dialog-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
}

.stock-dialog-select {
  min-width: 250px !important;
  width: 100%;
}

.simel-tabla {
  width: 49%;
  margin: 5px;
  border-radius: 5px;
}

.simel-tabla-full-width {
  width: 98%;
  margin: 5px;
  border-radius: 5px;
}

@media only screen and (max-width: 1040px) {
  .simel-tabla {
    width: 100%;
  }
}

.toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.toolbar-spaced-between {
  justify-content: space-between;
}

.toolbar-flex-end {
  justify-content: flex-end;
}

.separator { 
  width: 100%;
  margin: 10px
}

.form-content-container {
  width: 100%;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
}

 /* CARD */
.card-container{
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  display:flex;
  flex-direction:column;
  transition: all 0.2s ease-in-out;
}

.card-container:hover{
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
  transform: scale(1.01);
}

.card-header-container{
  width:100%;
  flex-grow:1;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.card-divider{
  width: 100%;
  height:1px;
  background-color: rgba(0,0,0,0.1);
  margin: 0px auto;
}

.card-divider-with-margin{
  width: 100%;
  height:1px;
  background-color: rgba(0,0,0,0.1);
  margin: 5px auto 5px;
}

.card-content-container{
  width:100%;
  flex-grow:1;
  box-sizing: border-box;
}

.card-infoitems-container{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position:relative;
  padding: 10px;
}