/* usuarios card */
.usuarios-card{
    width:100%;
    margin-bottom: 25px;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    display:flex;
    flex-direction: column;
}

.usuarios-card .half-container{
     flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    box-sizing:border-box;
}

.usuarios-card .half-container-divider{
    height: 1px; 
    width: 100%;
    background-color: rgba(0, 0, 0, 0.22);
    margin: 5px 0;
}

.usuarios-card .usuarios-list-container{
    max-height: 300px;
    overflow: auto;
}

.usuarios-card .usuarios-list-container .usuario-list-item{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor:pointer;
    margin: 8px 0;
}
.usuarios-card .usuarios-list-container .usuario-list-item:not(:last-child){
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.usuarios-card .usuarios-list-container .usuario-list-item .icon-button{
    opacity:0;
    transition: all 0.3s ease-in-out;
}

.usuarios-card .usuarios-list-container .usuario-list-item:hover .icon-button{
    opacity:1;
    transition: all 0.3s ease-in-out;
}

.usuarios-card .usuarios-signup-container{
    justify-content: center;
}

.equipo-actions-container{
    display:flex;
    justify-content: flex-end;
    align-items:center;
}

.usuarios-signup-form-container{
    margin:auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}