.historico-container{
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 0px 10px;
    box-sizing: border-box;
}

.historico-evento-container{
    width: 100%;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap:wrap;
    margin: 0 0 5px;
    padding: 5px 0px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.historico-evento-container .evento-fecha{
 width:220px;
}

.historico-evento-container .evento-nivel-container{
    display: flex;
    justify-content: flex-start;
    margin-right: 15px;
}

.historico-evento-container .evento-bombas-container{
    display:flex;
}

.historico-evento-container .evento-alarmas-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow:1;
}

.historico-evento-container .evento-nivel-container .texto,
.historico-evento-container .evento-alarmas-container .texto{
    margin-right: 15px;
    padding: 5px 10px;
    border-radius: 15px; 
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
}

@media only screen and (max-width: 960px)  and (orientation: portrait){
    .historico-evento-container .evento-fecha,
    .historico-evento-container .evento-nivel-container,
    .historico-evento-container .evento-bombas-container{
        width: 100%;
        margin-bottom: 5px;
    }    
}

.informacion-container{
    padding: 5px 10px;
}