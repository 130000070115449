
/* Equipos tabla */
.equipos-tabla{
    /* width:100%; */
    flex-grow: 1;
    /* margin-bottom: 25px; */
    /* border-radius:5px; */
    padding: 10px;
    flex-direction: column;
    justify-content: flex-start;
    /* overflow-x: hidden; */
    /* box-shadow: 0 0 10px 0 rgba(0,0,0,0.3); */
}

.equipos-tabla .equipo-outer-container{
    width: 100%;
    padding-bottom:10px;
    margin: 8px 0;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-bottom:1px solid #c1c1c1;
    border-radius: 8px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);
}

.equipos-tabla .equipo-main-container{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    flex-wrap: wrap;
}

.equipos-tabla .info-container{
    width: 60%;
    box-sizing: border-box;
    padding: 5px 0;
    display:flex;
    transition: all 0.3s ease-in-out;
}

.equipos-tabla .bomba-container{
    width: 40%;    
    box-sizing: border-box;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 960px)  and (orientation: portrait){
    .equipos-tabla .info-container{
        width: 100%;
    }
    .equipos-tabla .bomba-container{
        width: 100%;    
    }
}

.equipos-tabla .bomba-progress-track{
    flex-grow:1;  
    border-radius: 0 3px 3px 0;
    background-color: #c1c1c1;
    height: 8px;
    position:relative;
    margin-left:10px;
}

.equipos-tabla .bomba-progress{
    position:absolute;
    left:0;
    height: 100%;
    width:1px;
    background-color:#191970;
}

.equipos-tabla .bomba-progress-track-alerted{
    background: linear-gradient(to right, #c1c1c1, rgba(193, 193, 193, 0.2));
}

.equipos-tabla .bomba-nivel-texto{
    color: #2b2b2b;
    font-family: "Roboto" !important;
    font-weight: 100 !important;
}

.equipos-tabla .bomba-progress-alert{
    position:absolute;
    right:0;
    bottom: -8px;
    color: #ffc107;    
    animation: 1s estadoBombaIndicator infinite;
}

.equipos-tabla .equipo-outer-container:hover .info-container{
    transform: translateX(5px);
    transition: all 0.3s ease-in-out;
}

.equipos-tabla .equipo-outer-container:hover .bomba-container{
    transform: translateX(-5px);
    transition: all 0.3s ease-in-out;
}

.equipos-tabla .equipo-outer-container:hover .info-equipo-container{
    transform: translateX(5px);
    transition: all 0.3s ease-in-out;
}

.equipos-tabla .equipo-outer-container:hover .alarmas-bombas-container{
    transform: translateX(-5px);
    transition: all 0.3s ease-in-out;
}

.equipos-tabla .expand-icon{
    opacity:0;
    color: #2b2b2b;
    margin:auto 5px auto -20px;
    transition: all 0.3s ease-in-out;
}

.equipos-tabla .equipo-outer-container:hover .expand-icon,
.equipos-tabla .expand-icon-active{
    opacity:1;
    margin:auto 5px auto 0;
    transition: all 0.3s ease-in-out;
}

.equipos-tabla .equipo-secondary-container{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap:wrap;
    padding: 0px 20px;
    transition: all 0.3s ease-in-out;
}

.equipos-tabla .info-equipo-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px 0;
}

.equipos-tabla .info-equipo-container-compact{
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    flex-wrap: wrap;
}

.equipos-tabla .alarmas-bombas-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap:wrap;
    padding: 5px 0;   
    flex-grow: 1;
    transition: all 0.3s ease-in-out;
}

.estado-bombas-container{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap:wrap;
    margin: 5px 0;
}

.estado-bomba-container{
    display:flex;
}

.estado-bomba{
    width: 30px;
    height: 30px;
    margin-right: 20px;
    position: relative;
}

.equipos-tabla .alarmas-container{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 5px 0;
}

@media only screen and (max-width: 960px)  and (orientation: portrait){
    .equipos-tabla .info-equipo-container{
        width:100%;
    }    
    .equipos-tabla .estado-bombas-container{
        width:100%;
        justify-content: space-evenly;
    }
    .equipos-tabla .estado-bomba{
       transform:scale(1);
    }
    .equipos-tabla .alarmas-container{
        align-items: center;
    }
}

.engine{
    width:100%;
    height:auto;
}

 .estado-bomba-id{
    position: absolute;
    top:10px;
    right:-12px;
}

.estado-bomba-indicator{
    width: 10px;
    height: 10px;
   border-radius:50%;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    position: absolute;
    top:0;
    right:-12px;
    background-color: #4caf50;
    animation: 1s estadoBombaIndicator infinite;
}

.bomba-off{
    background-color: #f44336;
}

@keyframes estadoBombaIndicator{
    0%{
        transform: scale(1);
    }
    70%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(1);
    }
}

.equipos-tabla .equipo-actions-container{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap:wrap;
    padding: 0px 20px;
}

.equipos-tabla .equipo-action-button{
    cursor:pointer;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
    transition: all 0.3s ease-in-out;
}

.equipos-tabla .equipo-action-button svg{
    margin-right:5px;
    color: #414141;
    transition: all 0.3s ease-in-out;
}
 
.equipos-tabla .equipo-action-button:hover{
    background-color: #191970;
    color: #fff;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.8);
    transition: all 0.3s ease-in-out;
}

.equipos-tabla .equipo-action-button:hover svg{
    color: #fff;
    transition: all 0.3s ease-in-out;
}
