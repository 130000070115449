.equipo-sap-etapas-container{
    overflow-x: auto;
}
.equipo-sap-etapa-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    min-width:fit-content;
    border-bottom: rgba(0,0,0,0.1) 1px solid;
}
.equipo-sap-etapa-element {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    white-space: nowrap;
}
.equipo-sap-etapa-element-horizontal{
    flex-direction: row;
}
.equipo-sap-etapa-element:not(.equipo-sap-etapa-element-id) {
    flex-grow: 1;
}
.equipo-sap-etapa-element-id {
    font-size: 1.2rem;
    font-weight: 400;
    justify-content: center !important;
}
.equipo-sap-etapa-element span{
    white-space: nowrap;
}
.equipo-sap-etapa-element-estado-label {
    font-size: 1.2rem;
    font-weight: 400;
    justify-content: center !important;
}
.equipo-sap-estado-danger {
    border-left: rgb(244, 67, 54) solid 8px;
    background-color: rgba(244, 67, 54,0.3);
}
.equipo-sap-estado-warning {
    border-left: rgb(255, 193, 7) solid 8px;
    background-color: rgba(255, 193, 7,0.3);
}
.equipo-sap-estado-play {
    border-left: rgb(76, 175, 80) solid 8px;
    background-color: rgba(76, 175, 80,0.3);
}
.equipo-sap-estado-inactivo {
    border-left: rgb(158, 158, 158) solid 8px;
    background-color: rgba(158, 158, 158,0.3);
}
.equipo-sap-temperatura-container {
}
