.scrollable::-webkit-scrollbar {
  width: 8px; /* Tamaño del scroll en vertical */
}

/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
.scrollable::-webkit-scrollbar-thumb {
  background: #1b1b1b;
  border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.scrollable::-webkit-scrollbar-thumb:hover {
  background: #000;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.scrollable::-webkit-scrollbar-thumb:active {
  background: #000;
}

/* Ponemos un color de fondo y redondeamos las esquinas del track */
.scrollable::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

/* Cambiamos el fondo cuando esté en active o hover */
.scrollable::-webkit-scrollbar-track:hover,
.scrollable::-webkit-scrollbar-track:active {
  background: rgba(0, 0, 0, 0.4);
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.mensaje-container {
  width: 80%;
  padding: 15px;
  margin: 10px auto 15px auto;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.mensaje-exito {
  background-color: #4caf50;
}

.mensaje-fail {
  background-color: #f44336;
}

.mensaje-texto {
  color: #fff;
}

.texto-title {
  color: #2b2b2b;
  font-weight: 300 !important;
}

.section-title {
  margin-bottom: 15px !important;
}

.texto-numero {
  color: #2b2b2b;
  font-family: 'Roboto' !important;
  font-weight: 100 !important;
}

.texto {
  color: #2b2b2b;
  font-weight: 300 !important;
}

.texto-bold {
  font-weight: 400 !important;
}

.texto-link {
  color: #191970;
  font-weight: 500 !important;
  cursor: pointer;
}

.texto-link:hover {
  text-decoration: underline;
}

.form-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.formcontrol-selector {
  margin: 0 !important;
  width: 100% !important;
}

.table-container {
  width: 100%;
}

.mapa-container {
  height: 100%;
  width: 100%;
}

.custom-row {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.custom-row-subsuma {
  background-color: rgba(255, 193, 7, 0.3);
}

.custom-row-subsuma:hover {
  background-color: rgba(255, 193, 7, 0.4);
}

.custom-row-subsuma-final {
  background-color: rgba(255, 152, 0, 0.3);
}

.custom-row-subsuma-final:hover {
  background-color: rgba(255, 152, 0, 0.4);
}

.custom-row-subsuma td:first-child {
  padding: 5px 0px 5px 5px;
  border-left: 7px solid rgba(255, 193, 7, 1);
}

.custom-row-subsuma-final td:first-child {
  padding: 20px 0px 20px 5px;
  border-left: 7px solid rgba(255, 152, 0, 1);
}

.custom-row-cell {
  padding: 8px;
}

.custom-row-cell-centered-text {
  text-align: center;
}

.custom-row-cell-two-values-text {
  /* font-weight: bold; */
  color: #2b2b2b;
  font-size: 1.2rem;
}

.custom-row-cell-two-values-text-positive {
  color: #4caf50;
  vertical-align: middle;
}

.custom-row-cell-two-values-text-negative {
  color: #f44336;
  vertical-align: middle;
}

.custom-row-cell-two-values-separator {
  height: 20px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}

.mapa-bottom-bar {
  padding: 10px;
  /* position:absolute;
    bottom : 15px;
    left: 10px; */
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.9);
}

@media only screen and (max-width: 690px) and (orientation: portrait) {
  .mapa-bottom-bar {
    flex-direction: column;
    align-items: flex-start;
  }
}
