.home-container{
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    position:relative;
}

.home-header-left-container{
    position: absolute;
    width: 50vw;
    height: 100vh;
    top:0;
    left:0;
    overflow-x: hidden;
    overflow-y: hidden;
    background: #fff;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.5);
    z-index:2;
}

@media only screen and (max-width: 960px){
    .home-header-left-container{
        width: 100vw;
        height: 100vh;
    }
}

.home-background {
    width: 100%;
    height: 100%;
    position:absolute;
    top:0;
    left:0;
}

.img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.home-header-title{
    position: absolute;
    height: 100%;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 20vh 0 0; */
    color: #000;    
}

.home-header-title-img-container{
    width: 90%;
    height:20vh;
    display:flex;
}

.home-header-title-img{
    width: 100%;
    object-fit: contain;
}

.home-header-title-main{
    margin: 0 10px 10px !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    opacity:0;
    animation: titleStartAnimation 1s ease-in-out forwards;
}

@media only screen and (min-width: 960px){
    .home-header-title-main{
        font-size: 7.5vw !important;
    }
    .home-header-title-img-container{
        /* height:150px; */
    }
}

.home-header-title-subtitle{
    margin: 0 0 15px 15px !important;
    width:80%;
    border-bottom: 1px solid #414141;
    animation: titleStartAnimation 1.5s ease-in-out forwards;
}

.home-header-title-subtitle-description{
    width:80%;
    color: #2b2b2b;
    margin: 0 0 15px 15px !important;
    animation: titleStartAnimation 2s ease-in-out forwards;
}

@keyframes titleStartAnimation{
    from{
        transform: translateX(-30px);
        opacity:0;
    }
    to{
        transform: translateX(0);
        opacity:1;
    }
}

@media only screen and (max-width: 960px){
    .home-header-title{
        max-height:20vh;
        margin-top:3vh;
    }
    .home-header-title-subtitle{
        display:none;
    }
    .home-header-title-subtitle-description{
         display:none;
    }
    .img{
        object-position: 65%;
    }
}

.home-header-right-container{
    position: absolute;
    width: 50vw;
    height: 100vh;
    top:0;
    right:0;
    overflow-x: hidden;
    overflow-y: hidden;
    background: #fff;
    display:flex;
    flex-direction: column;
    justify-content: center;
    z-index:1;
}

.login-form-container{
    width: 65%;
    height: 100%;
    margin:auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

@media only screen and (max-width: 960px){
    .home-header-right-container{
        width: 100vw;
        /* height: 70vh; */
        /* top:30vh; */
        z-index:3;
        background: transparent;
    }
    .login-form-container{
        width: 95%;
        height: auto;
        border-radius: 8px;
        padding: 25px 15px;
        justify-content: flex-start;
        background: rgba(255,255,255,0.7);
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    }
}

.form-title{
    font-weight:200 !important;
    font-family: Montserrat, sans-serif !important;
}

.button-text{
    font-weight:200 !important;
    font-family: Montserrat, sans-serif !important;
}

.home-content {
   transform: translateZ(0px) scale(1) translateY(0%);
    background-color: rgba(255,255,255,1);
    height:100vh; 
    top: 120vh;
    padding:5vh 10px;
    position:relative;
    z-index:3
}

.section-container{
    width: 100%;
    box-sizing:border-box;
    margin: 40px auto 300px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.section-container .img-element{
    min-width: 200px;
    height: 400px;
}

.section-text{
    font-family: 'Montserrat', sans-serif !important;
    padding: 0 30px !important;
}

@media only screen and (max-width: 960px)  and (orientation: portrait){
    .section-container{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }    
    .section-container .img-element{
        width: 100%;
        height: 400px;
        margin:15px 0;
    }
    
    .section-title{
        transform: rotate(0);
        margin: 0 0 30px !important;
    }
    .section-text{
        padding: 0 10px !important;
    }
  }

.menu-button{
    position:fixed;
    top: 5px;
    right:15px;
    background-color: transparent; /* Green */
    color: #2b2b2b;
    height:80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border:none;
    cursor:pointer;
}

.menu-button .icon{
    font-size: 60px;
    font-weight: 400;
}