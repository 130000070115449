.homepage-container{
    display: flex;
    flex-direction: column;
}

/* topnav */
.topnav{
    width:100%;
    height:64px;
    box-sizing: border-box;
    padding: 5px 10px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
    z-index:1000;
}

.logo{
    /* width:150px; */
    height:100%;    
    overflow: hidden;
}

.logo-img{
    width:100%;
    height:auto;
    object-fit: contain;
    object-position: center;
}

.logo-text{
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    flex-grow:1;
}

.topnav-menu-container{
    display:flex;
    justify-content: flex-end;
    align-items: center;
}

@media only screen and (max-width: 960px)  and (orientation: portrait){
    .topnav{
        height: 120px;
    }
}

.topnav-element{
    margin: 5px 10px;
}

.topnav-selector{
    /* margin: 5px 10px !important; */
    width:100%;
}

.topnav-img{
    height: 49px;
    margin-bottom: 3px;
}

.hamburger-icon{
    color: rgba(65, 55, 55, 0.54);
}

.user-button-icon{
    margin-right: 5px;
    color: rgba(65, 55, 55, 0.54);
}

/* main */
.main{
    padding : 10px 10px 10px 250px !important;
}

.grid{
    width:100%;
    height: 100%;
    padding: 20px 25px;
    display:flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: scroll;
    scroll-behavior: smooth;
    box-sizing: border-box;
}

@media only screen and (max-width: 960px){
    .grid{
        padding: 20px 10px;
    }
    .main{
        padding : 74px 10px 10px !important;
    }    
}

/* ADMIN */
.equipo-user-selector{
    width: 100%;
    padding: 0px 10px 10px;
    box-sizing: border-box;
}

.form-control-buttons{
    width: 100%;
    padding: 0px 10px 10px;
    box-sizing: border-box;
    display:flex;
    justify-content: flex-end;
    align-items: center;
}

.form-control-buttons .button{
    /* flex-grow:1 !important; */
    margin: 10px 15px 0;
}

.form-control-buttons .button-filled{
    background-color:#191970;
}