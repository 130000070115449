.estado-bomba{
  width: 30px;
  height: 30px;
  margin-right: 20px;
  position: relative;
}

.estado-bomba-engine-img{
  width:100%;
  height:auto;
}

.estado-bomba-id{
  position: absolute;
  top:10px;
  right:-12px;
}

.estado-bomba-indicator{
  width: 10px;
  height: 10px;
 border-radius:50%;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  position: absolute;
  top:0;
  right:-12px;
  background-color: #4caf50;
  animation: 1s estadoBombaIndicator infinite;
}

.bomba-off{
  background-color: #f44336;
}

@keyframes estadoBombaIndicator{
  0%{
      transform: scale(1);
  }
  70%{
      transform: scale(1.1);
  }
  100%{
      transform: scale(1);
  }
}