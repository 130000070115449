/* ADMIN */
.equipo-user-selector{
    width: 100%;
    /* padding: 0px 10px 10px; */
    box-sizing: border-box;
    margin: 10px 0 0;
}

.form-control-buttons{
    width: 100%;
    /* padding: 0px 10px 10px; */
    box-sizing: border-box;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-control-buttons .button{
    /* flex-grow:1 !important; */
    /* margin: 10px 15px 0; */
    width: 100%;
}

.no-equipos-alert{    
    width:100%;
    font-size: 1.5rem;
    font-weight: 200;
}

/* EQUIPO CARD */
.equipo-card-container{
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    display:flex;
    flex-direction:column;
    transition: all 0.2s ease-in-out;
}

.equipo-card-container:hover{
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
    transform: scale(1.01);
}

.equipo-card-divider{
    width: 100%;
    height:1px;
    background-color: rgba(0,0,0,0.1);
    margin: 0px auto;
}

.equipo-card-avatar{
    width: 100%;
    height:  150px;
    overflow:hidden;
    background-color: #c1c1c1;
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.2)
}

.equipo-avatar-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.equipo-card-content-container{
    width:100%;
    flex-grow:1;
    box-sizing: border-box;
}

.equipo-card-name{
    font-size: 1.4rem;
    font-weight: 300;
    text-align: center;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.equipo-card-n-serie{
    font-size: 0.9rem;
    font-weight: 300;
    text-align: center;
    display: block;
    width: 95%;
    overflow: hidden;
    white-space: normal;
    /* text-overflow: ellipsis; */
    margin:auto;
    margin-bottom:5px;
}

.equipo-card-n-serie-full{
    width: 95%;
    white-space: normal;
}

.equipo-characteristics-container{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position:relative;
    padding: 10px;
}

.equipo-card-characteristic{
    font-size: 1rem;
    font-weight: 200;
    text-align: left;
}

.equipo-characteristic-icon{
    /* font-weight: bold; */
    color: #2b2b2b;
    font-size: 1.2rem;
}

.equipo-friends-title{
    font-size: 0.9rem;
    font-weight: 200;
    margin-top:5px;
}

.equipo-friends-container{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}

.equipo-friends-content{
    /* display:flex; */
    width:100%;
    font-size: 0.9rem;
    font-weight: 200;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-right:40px;
}

.equipo-friend-avatar{
    width:40px;
    height:40px;
    border-radius: 20px;
    background-color: #c1c1c1;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    margin: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;    
}

.equipo-actions-container{
    display:flex;
    justify-content: flex-end;
    align-items:center;
}

.equipo-content-hide{    
    max-height:0;
    overflow:hidden;
    opacity:0;
    transition: all 0.1s ease-in-out;
}

.equipo-content-show{  
    max-height: 500px;
    opacity:1;
}

.equipo-simel-list-item{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor:pointer;
    margin: 8px 0;
}

.equipo-simel-list-item .icon-button{
    opacity:0;
    transition: all 0.3s ease-in-out;
}

.equipo-simel-list-item:hover .icon-button{
    opacity:1;
    transition: all 0.3s ease-in-out;
}